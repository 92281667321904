const sliderBox = document.querySelector(".slider");

const slides = document.querySelectorAll(".slide");
const btnLeft = document.querySelector(".btn-left");
const btnRight = document.querySelector(".btn-right");
const backBtn = document.querySelector(".back");

let curSlide = 0;
let maxSlides = slides.length;

////////////////////////////////////////
///////////////////////////////////////
if (btnRight) {
  btnRight.addEventListener("click", () => {
    hide();
    curSlide++;

    if (curSlide === maxSlides) {
      curSlide = 0;
    }

    slides[curSlide].style.display = "block";
  });
}
if (btnLeft) {
  btnLeft.addEventListener("click", () => {
    hide();
    if (curSlide === 0) {
      curSlide = maxSlides;
    }
    curSlide--;

    slides[curSlide].style.display = "block";
  });
}

if (backBtn) {
  backBtn.addEventListener("click", () => {
    window.location.href = "index.html#projects";
  });
}
const hide = () => {
  slides.forEach((slide) => (slide.style.display = "none"));
};
